




















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Breadcrumb from '@/components/Breadcrumb.vue';
import Component, {mixins} from 'vue-class-component';
import {Prop, Vue, Watch} from 'vue-property-decorator';
import _ from 'lodash';
import AXIOS from '@/services/axios-wrapper-hrRequest';
import moment from 'moment';
import {
  HrDbUserDto,
  HrDbUserInitPWDto,
  HrRequestSummary,
  HrRequestDetail,
  HrRequestExtraInfo,
  AttachFileType,
  HrRequestAttachFilesResponse,
  HrRequestFormUploadFiles,
  HrRequestJikmuOptionItem,
  HrRequestInitPW,
  HrRequestAttachFile,
  HrRequestAttachFileUploadResultDto,
} from '@/model-hr';
import GeneralUtil from '@/util/GeneralUtil';
import {BV_OptionItem, FormUploadFiles} from '@/models';
import {BizSirenResultDto} from '@/services/LoginModels';
import {loginService} from '@/services/login.service';
import FormAttachHrFiles from '@/components/FormAttachHrFiles.vue';
import HrRequestView from '@/components/HrRequestView.vue';
import {BreadcrumbInfo, HrBoardArticle} from '@/model-hr';
import hrBoardService from '@/services/hr.service';
import CONST from '@/util/Const';

interface YourComponentType extends Vue {
  clear(): any;
}

interface ValdateComponentType extends Vue {
  validate(): any;
}

@Component({
  components: {
    Breadcrumb,
    GeneralUtil,
    FormAttachHrFiles,
    HrRequestView,
  },
})
export default class HrRequest extends mixins(GeneralUtil) {
  agree1 = false;
  agree2 = false;
  agree3 = false;
  agree4 = false;

  hrRequestId = this.$route.params.hrRequestId as unknown as number;
  dtoHrBoard: HrBoardArticle | null = null;
  newPassword = '';
  newPasswordRepeat = '';
  introduce_breadcrumb: BreadcrumbInfo = {
    toplink: 'HOME',
    prevlink: '인재채용',
    present: '채용공고',
  };
  v_datepicker_masks = {
    input: 'YYYY-MM-DD',
  };
  workingEnableDay: Date = moment().toDate();
  private text1 =
    'SK PINX 개인정보처리방침\n \nSK PINX(thepinx.co.kr, 이하 PINX)이(가) 취급하는 모든 개인정보는 개인정보보호법 등 관련 법령상의 개인정보보호 규정을 준수하여 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.\n';
  private text2 =
    '1. 개인정보 수집 및 이용주체\n \n本 채용홈페이지를 통해 제출하는 지원서는 귀하께서 지원서를 제출하는 회사(이하 채용회사)가 직접 접수하고 관리하며, 향후 지원서 관리책임은 채용회사에 있습니다.\n\n 2. 동의를 거부할 권리 및 동의 거부에 따른 불이익\n' +
    '지원자는 아래 개인정보 제공 등에 관해 동의하지 않을 권리가 있습니다.\n' +
    '다만, 지원서를 통해 제공받는 정보는 회사의 채용 전형에 필수적인 항목으로 해당 정보를 제공받지 못할 경우 회사는 공정한 선발전형을 진행할 수 없습니다.\n' +
    '따라서 개인정보 제공에 대해 동의하지 않는 경우 채용 전형에 지원이 제한될 수 있습니다.\n' +
    '지원자의 동의 거부 권리 및 동의 거부에 따른 불이익은 아래에 제시되는 모든 동의사항에 해당됩니다.';

  hrUserDto: HrDbUserDto = {
    kor_nm: '',
    biryymm_dt: '',
    email_nm: '',
    sex_cd: 'M',
    scret_no: '',
    hp_no: '',
  };
  hrUserDtoNew: HrRequestSummary = {
    koNm: '',
    biryymmDt: '',
    emailNm: '',
    sexCd: 'm',
    scretNo: '',
    hpNo: '',
  };
  hrUserDtoInitPW: HrRequestInitPW = {
    koNm: '',
    biryymmDt: '',
    emailNm: '',
    hpNo: '',
    hrBoardId: 0,
  };
  hrDetailDto: HrRequestDetail = {
    koNm: '',
    biryymmDt: '',
    emailNm: '',
    sexCd: '',
    scretNo: '',
    hpNo: '',
    engLnmNm: '',
    ntnlNm: '',
    fgNm: '',
    telNo: '',
    addrNm: '',
    dutyDcDepart1: '',
    dutyDc1: '',
    dutyDcDepart2: '',
    dutyDc2: '',
    requestPath: '',
    requestPathSub: '',
    workingStatus: '',
    workingEnableDay: '',
    workingWantPay: '',
    prGrowth: '',
    prStrenghtWeak: '',
    prMotivation: '',
    prDream: '',
    extraInfos: [],
    attachFiles: [],
    isCompleted: false,
    isTempPw: false,
  };
  fields_highschool: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'fromDt',
      label: '입학년월',
    },
    {
      key: 'toDt',
      label: '졸업년월',
      //thClass: 'red',
    },
    {
      key: 'mclsDc1',
      label: '학교명',
    },
    {
      key: 'mclsDc2',
      label: '소재지',
    },
    {
      key: 'mclsDc3',
      label: '계열',
    },
    {
      key: 'mclsDc4',
      label: '졸업여부',
    },
    {
      key: 'action',
    },
  ];
  fields_academic: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'fromDt',
      label: '입학년월',
    },
    {
      key: 'toDt',
      label: '졸업년월',
      //thClass: 'red',
    },
    {
      key: 'mclsDc1',
      label: '학교명',
    },
    {
      key: 'mclsDc2',
      label: '학과(전공)',
    },
    {
      key: 'mclsDc3',
      label: '학년',
    },
    {
      key: 'mclsDc4',
      label: '졸업여부',
    },
    {
      key: 'action',
    },
  ];
  fields_career: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'mclsDc1',
      label: '회사명',
    },
    {
      key: 'period',
      label: '기간',
    },
    {
      key: 'mclsDc2',
      label: '직위',
    },
    {
      key: 'mclsDc3',
      label: '직무',
    },
    {
      key: 'beforePay',
      label: '최종연봉',
    },
    {
      key: 'mclsDc4',
      label: '소재지',
    },
    {
      key: 'action',
    },
  ];
  fields_career_skill: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'mclsDc1',
      label: '회사명',
    },
    {
      key: 'mclsDc2',
      label: '직무',
    },
    {
      key: 'mclsDc3',
      label: '업무내용',
    },
    {
      key: 'action',
    },
  ];
  fields_certificate: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'mclsDc1',
      label: '종류',
    },
    {
      key: 'mclsDc2',
      label: '등급',
    },
    {
      key: 'fromDt',
      label: '취득일',
    },
    {
      key: 'mclsDc3',
      label: '발급기관',
    },
    {
      key: 'action',
    },
  ];
  fields_language: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'mclsDc1',
      label: '종류',
    },
    {
      key: 'mclsDc2',
      label: '점수',
    },
    {
      key: 'fromDt',
      label: '취득일',
    },
    {
      key: 'mclsDc3',
      label: '시험기관',
    },
    {
      key: 'action',
    },
  ];
  fields_prise: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'mclsDc1',
      label: '명칭 및 단체명',
    },
    {
      key: 'mclsDc2',
      label: '수상내용',
    },
    {
      key: 'fromDt',
      label: '수상일자',
    },
    {
      key: 'action',
    },
  ];
  fields_social: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'mclsDc1',
      label: '소속명',
    },
    {
      key: 'mclsDc2',
      label: '활동내용',
    },
    {
      key: 'period',
      label: '활동기간',
    },
    {
      key: 'action',
    },
  ];
  fields_army: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'mclsDc1',
      label: '구분',
    },
    {
      key: 'period',
      label: '복무기간',
    },
    {
      key: 'mclsDc2',
      label: '면제사유',
    },
    {
      key: 'mclsDc3',
      label: '역종',
    },
    {
      key: 'mclsDc4',
      label: '계급',
    },
    {
      key: 'mclsDc5',
      label: '군별/병과',
    },
    {
      key: 'action',
    },
  ];
  fields_bohun: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'mclsDc1',
      label: '보훈 번호',
    },
    {
      key: 'mclsDc2',
      label: '보훈 대상자',
    },
    {
      key: 'mclsDc3',
      label: '관계',
    },
    {
      key: 'action',
    },
  ];
  fields_jangae: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'mclsDc1',
      label: '장애종류',
    },
    {
      key: 'mclsDc2',
      label: '장애급수',
    },
    {
      key: 'action',
    },
  ];
  options_job_Category: BV_OptionItem[] = [
    {value: '골프클럽', text: '골프클럽'},
    {value: '포도호텔', text: '포도호텔'},
    {value: '디아넥스호텔', text: '디아넥스호텔'},
    {value: '비오토피아', text: '비오토피아'},
  ];
  jikmuOptionItems: HrRequestJikmuOptionItem[] = [
    {
      depart: '경영지원팀',
      options: [
        {value: 'HR', text: 'HR'},
        {value: '재무회계', text: '재무회계'},
        {value: '경리출납', text: '경리출납'},
        {value: '총무', text: '총무'},
        {value: '구매', text: '구매'},
        {value: '운전', text: '운전'},
        {value: '기획', text: '기획'},
        {value: '홍보', text: '홍보'},
        {value: '기숙사관리', text: '기숙사관리'},
      ],
    },
    {
      depart: '식음서비스팀',
      options: [{value: '서버', text: '서버'}],
    },
    {
      depart: '조리팀',
      options: [
        {value: '조리사', text: '조리사'},
        {value: '찬모', text: '찬모'},
        {value: '찬모보조', text: '찬모보조'},
        {value: '주방보조', text: '주방보조'},
        {value: '파티셰', text: '파티셰'},
        {value: '펜츄리', text: '펜츄리'},
      ],
    },
    {
      depart: '객실팀',
      options: [
        {value: '프런트', text: '프런트'},
        {value: '컨시어지', text: '컨시어지'},
        {value: '객실관리', text: '객실관리'},
        {value: '컨시어지매니저', text: '컨시어지매니저'},
        {value: '안전요원', text: '안전요원'},
        {value: '프로샵', text: '프로샵'},
      ],
    },
    {
      depart: '경기운영팀',
      options: [
        {value: '회원관리', text: '회원관리'},
        {value: '카트관리', text: '카트관리'},
        {value: '경기진행', text: '경기진행'},
      ],
    },
    {
      depart: '시설관리팀',
      options: [
        {value: '행정사무', text: '행정사무'},
        {value: '기계관리', text: '기계관리'},
        {value: '시설관리', text: '시설관리'},
        {value: '전기관리', text: '전기관리'},
      ],
    },
    {
      depart: '마케팅팀',
      options: [
        {value: 'RM', text: 'RM'},
        {value: '세일즈', text: '세일즈'},
        {value: '마케팅', text: '마케팅'},
      ],
    },
    {
      depart: '코스TF',
      options: [
        {value: '조경관리', text: '조경관리'},
        {value: '영선관리', text: '영선관리'},
        {value: '코스관리', text: '코스관리'},
      ],
    },
    {
      depart: 'ESGTF',
      options: [{value: 'ESG기획', text: 'ESG기획'}],
    },
    {
      depart: 'DTTF',
      options: [{value: 'IT', text: 'IT'}],
    },
  ];
  options_workingStatus: BV_OptionItem[] = [
    {value: '미취업중', text: '미 취업중'},
    {value: '재직중', text: '재직중'},
  ];
  options_fgNm: BV_OptionItem[] = [
    {value: '신입', text: '신입'},
    {value: '경력', text: '경력'},
    {value: '신입경력', text: '신입/경력'},
    {value: '인턴쉽', text: '인턴쉽'},
  ];
  options_requestPath: BV_OptionItem[] = [
    {value: '직원추천', text: '직원추천'},
    {value: '학교추천', text: '학교추천'},
    {value: '채용사이트', text: '채용사이트'},
    {value: '직접문의', text: '직접문의'},
    {value: '신문기사(온라인,오프라인)', text: '신문기사(온라인, 오프라인)'},
    {value: '기타', text: '기타'},
  ];
  requestPathSubOptionItems: HrRequestJikmuOptionItem[] = [
    {
      depart: '학교추천',
      options: [
        {value: '교수추천', text: '교수추천'},
        {value: '교내게시판(채용포스터 등)', text: '교내게시판(채용포스터 등)'},
        {value: '채용설명회', text: '채용설명회'},
      ],
    },
    {
      depart: '채용사이트',
      options: [
        {value: '사람인', text: '사람인'},
        {value: '잡코리아', text: '잡코리아'},
        {value: '자소설 닷컴', text: '자소설 닷컴'},
        {value: 'SK Careers', text: 'SK Careers'},
      ],
    },
  ];
  options_army_gubun: BV_OptionItem[] = [
    {value: '군필', text: '군필'},
    {value: '미필', text: '미필'},
    {value: '면제', text: '면제'},
  ];
  options_highschool_jolup: BV_OptionItem[] = [
    {value: '재학', text: '재학'},
    {value: '중퇴', text: '중퇴'},
    {value: '검정', text: '검정'},
    {value: '졸업', text: '졸업'},
  ];
  options_academic_jolup: BV_OptionItem[] = [
    {value: '재학', text: '재학'},
    {value: '중퇴', text: '중퇴'},
    {value: '수료', text: '수료'},
    {value: '전문학사졸업', text: '전문학사 졸업'},
    {value: '학사졸업', text: '학사 졸업'},
  ];
  options_academic2_jolup: BV_OptionItem[] = [
    {value: '재학', text: '재학'},
    {value: '중퇴', text: '중퇴'},
    {value: '수료', text: '수료'},
    {value: '석사졸업', text: '석사 졸업'},
    {value: '박사졸업', text: '박사 졸업'},
  ];
  userBirthDay: Date = moment().toDate();
  //1로그인 정보입력, 2신규사용자 인증 입력, 4. 상세정보입력페이지
  pageState = 1;
  // pageState = 11;
  tabIndex = 0;
  chkUpload = false;
  chkCollectInfo = false;
  todayDate = moment().format('YYYY년 MM월 DD일');

  private identityAuth = false;
  private host = window.location.protocol + '//' + window.location.host;
  private reqInfo = '';
  private windowRef: any = null;
  private repeatPassword = '';
  private duty_dc1 = '';
  private duty_dc2 = '';
  //증명사진
  image = null;
  idPhoto: string | null = null;

  fileUploadJolup: HrRequestFormUploadFiles = {
    uploading: [], // 파일 업로드 (아직 업로드 안된 것)
    uploaded: [], // 이미 업로드된 파일들 (수정 - BoardAttachFile)
    uploadedToDelete: [], // 이미 업로드된 파일들 중 삭제 되어야 할 것들 (BoardAttachFile)
    uploadingPic: [], // base64 타입의 썸네일 이미지
  };
  fileUploadSeongjek: HrRequestFormUploadFiles = {
    uploading: [], // 파일 업로드 (아직 업로드 안된 것)
    uploaded: [], // 이미 업로드된 파일들 (수정 - BoardAttachFile)
    uploadedToDelete: [], // 이미 업로드된 파일들 중 삭제 되어야 할 것들 (BoardAttachFile)
    uploadingPic: [], // base64 타입의 썸네일 이미지
  };
  fileUploadJa: HrRequestFormUploadFiles = {
    uploading: [], // 파일 업로드 (아직 업로드 안된 것)
    uploaded: [], // 이미 업로드된 파일들 (수정 - BoardAttachFile)
    uploadedToDelete: [], // 이미 업로드된 파일들 중 삭제 되어야 할 것들 (BoardAttachFile)
    uploadingPic: [], // base64 타입의 썸네일 이미지
  };
  fileUploadCareer: HrRequestFormUploadFiles = {
    uploading: [], // 파일 업로드 (아직 업로드 안된 것)
    uploaded: [], // 이미 업로드된 파일들 (수정 - BoardAttachFile)
    uploadedToDelete: [], // 이미 업로드된 파일들 중 삭제 되어야 할 것들 (BoardAttachFile)
    uploadingPic: [], // base64 타입의 썸네일 이미지
  };
  fileUploadWoncheon: HrRequestFormUploadFiles = {
    uploading: [], // 파일 업로드 (아직 업로드 안된 것)
    uploaded: [], // 이미 업로드된 파일들 (수정 - BoardAttachFile)
    uploadedToDelete: [], // 이미 업로드된 파일들 중 삭제 되어야 할 것들 (BoardAttachFile)
    uploadingPic: [], // base64 타입의 썸네일 이미지
  };

  $refs!: {
    fileUploadJolup: YourComponentType;
    fileUploadSeongjek: YourComponentType;
    fileUploadJa: YourComponentType;
    fileUploadCareer: YourComponentType;
    fileUploadWoncheon: YourComponentType;
    observer: ValdateComponentType;
    observer2: ValdateComponentType;
  };

  emailAuthCode = '';
  chkEmailAuth = false;

  getMojip2Options(depart: string): BV_OptionItem[] {
    if (!depart) {
      const emptyOptions: BV_OptionItem[] = [];
      return emptyOptions;
    }

    let mojip2Options = this.jikmuOptionItems.filter(x => x.depart === depart);
    if (mojip2Options.length == 0) {
      const emptyOptions: BV_OptionItem[] = [];
      return emptyOptions;
    }
    return mojip2Options[0].options;
  }

  getDeaprtOptions(): BV_OptionItem[] {
    if (!this.dtoHrBoard?.hrRecruitInfoList) {
      const emptyOptions: BV_OptionItem[] = [];
      return emptyOptions;
    } else {
      //모집부서 가져오기
      const returnOptions: BV_OptionItem[] = [];
      for (const item of this.dtoHrBoard.hrRecruitInfoList) {
        if (returnOptions.filter(x => x.value === item.mogip1).length === 0) {
          const addItem: BV_OptionItem = {
            text: item.mogip1,
            value: item.mogip1,
          };
          returnOptions.push(addItem);
        }
      }

      return returnOptions;
    }
  }

  getDutyOptions(depart: string): BV_OptionItem[] {
    if (!this.dtoHrBoard?.hrRecruitInfoList || !depart) {
      const emptyOptions: BV_OptionItem[] = [];
      return emptyOptions;
    } else {
      //모집부서 가져오기
      const returnOptions: BV_OptionItem[] = [];
      for (const item of this.dtoHrBoard.hrRecruitInfoList.filter(y => y.mogip1 === depart)) {
        if (returnOptions.filter(x => x.value === item.mogip2).length === 0) {
          const addItem: BV_OptionItem = {
            text: item.mogip2,
            value: item.mogip2,
          };
          returnOptions.push(addItem);
        }
      }

      return returnOptions;
    }
  }
  getRequestPathSubOptions(depart: string): BV_OptionItem[] {
    if (!depart) {
      const emptyOptions: BV_OptionItem[] = [];
      return emptyOptions;
    } else {
      //지원경로 sub 리스트 가져오기
      let requestPathSubs = this.requestPathSubOptionItems.filter(x => x.depart === depart);
      if (requestPathSubs.length == 0) {
        const emptyOptions: BV_OptionItem[] = [];
        return emptyOptions;
      }
      return requestPathSubs[0].options;
    }
  }
  changeReqeustPath() {
    this.hrDetailDto.requestPathSub = '';
  }

  onDeleteUploadedFileJolup(idx: number) {
    this.fileUploadJolup.uploadedToDelete.push(...this.fileUploadJolup.uploaded.splice(idx, 1));
  }

  onDeleteUploadedFileSeongjek(idx: number) {
    this.fileUploadSeongjek.uploadedToDelete.push(...this.fileUploadSeongjek.uploaded.splice(idx, 1));
  }

  onDeleteUploadedFileJa(idx: number) {
    this.fileUploadJa.uploadedToDelete.push(...this.fileUploadJa.uploaded.splice(idx, 1));
  }

  onDeleteUploadedFileCareer(idx: number) {
    this.fileUploadCareer.uploadedToDelete.push(...this.fileUploadCareer.uploaded.splice(idx, 1));
  }

  onDeleteUploadedFileWoncheon(idx: number) {
    this.fileUploadWoncheon.uploadedToDelete.push(...this.fileUploadWoncheon.uploaded.splice(idx, 1));
  }

  mounted(): void {
    // eslint-disable-next-line
    window.recvSirenPhoneResult = this.recvSirenPhoneResult;
    this.$store.dispatch('Display/changeName', {name: '채용공고'});
  }

  created() {
    this.fetchData();
  }

  async fetchData() {
    try {
      this.showLoadingModal();
      const response = await hrBoardService.getArticleDetails(this.hrRequestId, 'HrBoardArticleSummary');
      this.dtoHrBoard = response;
    } catch (error) {
      console.error(error);
    } finally {
      this.hideLoadingModal();
    }
  }

  get hasImage() {
    return !!this.image;
  }

  get hasImageSrc() {
    return !!this.idPhoto;
  }

  get isEnableRequest() {
    if (this.dtoHrBoard === null) return false;
    else {
      if (this.dtoHrBoard.articleStatus !== '서류전형접수중') return false;
      else {
        let endDate = moment(this.dtoHrBoard.endDate!);
        endDate.set({hour: 23, minute: 59, second: 59});
        let startDate = moment(this.dtoHrBoard.startDate!);
        startDate.set({hour: 0, minute: 0, second: 0});
        const nowMoment = moment();
        if (nowMoment >= startDate && nowMoment < endDate) return true;
        else return false;
      }
    }
  }

  base64Encode(data: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  modifyHrRequestDetail() {
    this.pageState = 4;
  }

  @Watch('image', {immediate: true, deep: true})
  public imageChanged(newValue: string, oldValue: string) {
    if (newValue !== oldValue) {
      if (newValue) {
        this.base64Encode(newValue)
          .then(value => {
            this.idPhoto = value as any;
            //this.uploadImage();
          })
          .catch(() => {
            this.idPhoto = null;
          });
      } else {
        this.idPhoto = null;
      }
    }
  }

  @Watch('agree4', {immediate: true, deep: true})
  public agree4Changed(newValue: boolean, oldValue: boolean) {
    if (newValue !== oldValue) {
      this.agree1 = newValue;
      this.agree2 = newValue;
      this.agree3 = newValue;
    }
  }

  // @Watch('hrDetailDto.requestPath', {immediate: true, deep: true})
  // public requestPathChanged(newValue: boolean, oldValue: boolean) {
  //   if (newValue !== oldValue) {
  //     this.hrDetailDto.requestPathSub = '';
  //   }
  // }

  hideGuidePage() {
    this.pageState = 1;
  }

  showPasswdInit() {
    this.pageState = -1;
  }

  changePageState(pageNumber: number) {
    this.pageState = pageNumber;
  }

  async checkDuplicate(search: any): Promise<boolean> {
    const searchQuery = this.build_search(search, null);
    const responseDuplicate = await hrBoardService.getHrRequestSearch(searchQuery);
    if (responseDuplicate._embedded.hrRequests.length > 0) {
      return true;
    } else return false;
  }

  async addHrUser() {
    if (!this.chkEmailAuth) {
      this.$bvModal.msgBoxOk('이메일 인증을 해주세요', {
        title: '이메일인증필요',
        okVariant: 'danger',
        centered: true,
      });
      return;
    }
    if (this.hrUserDtoNew.koNm == '') {
      this.$bvModal.msgBoxOk('핸드폰을 인증해주세요', {
        title: '인증필요',
        okVariant: 'danger',
        centered: true,
      });
      return;
    }
    if (this.hrUserDtoNew.emailNm == '') {
      this.$bvModal.msgBoxOk('이메일을 입력해주세요', {
        title: '이메일 인증필요',
        okVariant: 'danger',
        centered: true,
      });
      return;
    }
    if (this.hrUserDtoNew.scretNo == '') {
      this.$bvModal.msgBoxOk('비밀번호를 입력해주세요', {
        title: '비밀번호 입력필요',
        okVariant: 'danger',
        centered: true,
      });
      return;
    }
    if (this.hrUserDtoNew.scretNo != this.repeatPassword) {
      this.$bvModal.msgBoxOk('비밀번호가 다릅니다', {
        title: '비밀번호 확인필요',
        okVariant: 'danger',
        centered: true,
      });
      return;
    }

    const isValid = await this.$refs.observer.validate();
    if (!isValid) {
      await this.$bvModal.msgBoxOk('비밀번호 규칙에 맞게 입력해주세요', {
        title: '비밀번호 규칙확인',
      });
      return;
    }

    const confirm = await this.$bvModal.msgBoxConfirm('입력을 완료하고 다음으로 진행하시겠습니까?', {
      title: '입력정보 확인',
      okVariant: 'success',
      cancelTitle: '취소',
      centered: true,
    });
    if (!confirm) return;

    // try {
    this.showLoadingModal();
    //중복체크 필요

    const postData = {
      koNm: this.hrUserDtoNew.koNm,
      emailNm: this.hrUserDtoNew.emailNm,
      scretNo: this.hrUserDtoNew.scretNo,
      sexCd: this.hrUserDtoNew.sexCd,
      biryymmDt: this.hrUserDtoNew.biryymmDt,
      hpNo: this.hrUserDtoNew.hpNo,
      hrBoardInfoId: this.hrRequestId,
    };

    const apiPath = `/hrrequest/addHrUser/${this.retInfo}`;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    //토큰 저장
    await AXIOS.post(apiPath, postData)
      .then(async response => {
        this.accessToken = response.data.accessToken;
        await this.fetchHrRequestData(false);
        this.pageState = 4;
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 409) {
            self.$bvModal
              .msgBoxOk(self.htmlToVNode('중복 지원 불가입니다'), {
                title: '중복지원불가',
                okVariant: 'success',
                okTitle: '확인',
                centered: true,
              })
              .then(value => {
                self.$router.go(-1);
              });
            return;
          } else if (error.response.status === 403) {
            self.$bvModal
              .msgBoxOk(self.htmlToVNode('본 채용공고 지원 기간이 아닙니다'), {
                title: '알림',
                okVariant: 'success',
                okTitle: '확인',
                centered: true,
              })
              .then(value => {
                self.$router.go(-1);
              });
            return;
          }
          self.$bvModal.msgBoxOk('지원자 저장중 오류발생', {
            title: '지원자 저장중 오류발생',
            centered: true,
          });
        } else if (error.request) {
          self.$bvModal.msgBoxOk('지원자 저장중 오류발생', {
            title: '지원자 저장중 오류발생',
            centered: true,
          });
          console.log(error.request);
        } else {
          // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
          self.$bvModal.msgBoxOk('지원자 저장중 오류발생', {
            title: '지원자 저장중 오류발생',
            centered: true,
          });
          //console.log('Error', error.message);
        }
        //console.log(error.config);
      })
      .finally(() => {
        self.hideLoadingModal();
      });
  }

  private retInfo = '';

  recvSirenPhoneResult(type: string, result: BizSirenResultDto, retInfo: string): void {
    this.hrUserDtoNew.koNm = result.name;
    this.hrUserDtoNew.biryymmDt = result.birYMD;
    this.hrUserDtoNew.sexCd = result.sex;
    this.hrUserDtoNew.hpNo = result.cellNo;

    this.hrUserDtoInitPW.koNm = result.name;
    this.hrUserDtoInitPW.biryymmDt = result.birYMD;
    this.hrUserDtoInitPW.hpNo = result.cellNo;

    this.retInfo = retInfo;
    this.identityAuth = true;
  }

  openPhoneAuthPopup(): void {
    //console.log(this.authPhoneDto);

    //핸드폰 본인인증 창열
    loginService.getBizSirenEncode().then(response => {
      if (response.status == 200) {
        const responseData = response.data;
        this.reqInfo = responseData;
        this.windowRef = window.open(
          '',
          '',
          'width=430, height=560, resizable=1, scrollbars=no, status=0, titlebar=0, toolbar=0, left=300, top=200',
        );
        const popupDocument = this.windowRef.document;
        const frm = popupDocument.createElement('form');
        frm.setAttribute('id', 'auth-form');
        frm.setAttribute('action', 'https://pcc.siren24.com/pcc_V3/jsp/pcc_V3_j10_v2.jsp');
        frm.setAttribute('method', 'post');
        frm.setAttribute('target', '_self');

        const element1 = popupDocument.createElement('input');
        element1.type = 'hidden';
        element1.value = this.reqInfo;
        element1.name = 'reqInfo';
        frm.appendChild(element1);

        const element2 = popupDocument.createElement('input');
        element2.type = 'hidden';
        element2.value = '32' + this.host + '/noHeader/p/bizSirenCallBackPhone';
        element2.name = 'retUrl';
        frm.appendChild(element2);

        const element3 = popupDocument.createElement('input');
        element3.type = 'hidden';
        element3.value = '2';
        element3.name = 'verSion';
        frm.appendChild(element3);

        popupDocument.body.appendChild(frm);
        //this.windowRef.document.body.appendChild(this.$el);
        //copyStyles(window.document, this.windowRef.document);
        //this.windowRef.addEventListener('beforeunload', this.closePopup);
        frm.submit();
      } else {
        //http response 오류 처리
      }
    });
  }

  async deleteExtraInfos(id: number) {
    this.hrDetailDto.extraInfos = this.hrDetailDto.extraInfos!.filter((item, i) => item.id !== id);
    //DB에 있을경우 삭제
    if (id > 0) {
      const apiPath = `/hrrequest/deleteHrRequestExtraInfo/${id}`;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      //토큰 저장
      await AXIOS.get(apiPath)
        .then(async response => {
          console.log('삭제성공');
        })
        .catch(function (error) {
          self.$bvModal.msgBoxOk('DB 삭제중 오류발생', {
            title: 'DB 삭제중 오류발생',
            centered: true,
          });
          //console.log(error.config);
        })
        .finally(() => {
          self.hideLoadingModal();
        });
    }
  }

  addExtraInfo(extraType: string) {
    let tempId = -(this.hrDetailDto.extraInfos!.length + 1);

    const newRow: HrRequestExtraInfo = {
      id: tempId,
      extraType: extraType,
      fromDt: undefined,
      toDt: undefined,
      mclsDc1: '',
      mclsDc2: '',
      mclsDc3: '',
      mclsDc4: '',
    };
    this.hrDetailDto.extraInfos!.push(newRow);
  }

  // updateTestValue() {
  //   this.hrDetailDto = this.hrDetailDtoTest;
  // }

  saveHrUser() {
    this.updateHrDetail(false);
  }

  updateHrDetailConfirm() {
    if (!this.chkUpload) {
      this.$bvModal.msgBoxOk('지원 확인 동의 체크를 해주세요', {
        title: '동의필요',
        okVariant: 'danger',
        centered: true,
      });
      return;
    }
    this.$bvModal.show('hr_completed_confirm');
  }

  async updateHrDetailCompleted() {
    this.hrDetailDto.isCompleted = true;
    this.hrDetailDto.requestDate = moment().toDate();
    await this.updateHrDetail(true);
  }

  changePrGrowth(changedText: string) {
    console.log(changedText);
    if (changedText!.length > 500) {
      this.hrDetailDto.prGrowth = changedText.substr(0, 500);
    }
  }

  changePrStrenghtWeak(changedText: string) {
    console.log(changedText);
    if (changedText!.length > 500) {
      this.hrDetailDto.prStrenghtWeak = changedText.substr(0, 500);
    }
  }

  changePrMotivation(changedText: string) {
    console.log(changedText);
    if (changedText!.length > 500) {
      this.hrDetailDto.prMotivation = changedText.substr(0, 500);
    }
  }

  changePrDream(changedText: string) {
    console.log(changedText);
    if (changedText!.length > 500) {
      this.hrDetailDto.prDream = changedText.substr(0, 500);
    }
  }

  async updateHrDetail(isCompltedSave: boolean) {
    this.showLoadingModal();
    let tempUpdate = true;
    let notInput = '';
    this.getLocalVariable();
    if (
      (isCompltedSave && (this.hrDetailDto.engLnmNm == '' || this.hrDetailDto.engLnmNm == null)) ||
      (this.tabIndex === 0 && (this.hrDetailDto.engLnmNm == '' || this.hrDetailDto.engLnmNm == null))
    ) {
      tempUpdate = false;
      notInput = '영문성명 FirstName';
    }
    if (
      (isCompltedSave && (this.hrDetailDto.engLnmNmLast == '' || this.hrDetailDto.engLnmNmLast == null)) ||
      (this.tabIndex === 0 && (this.hrDetailDto.engLnmNmLast == '' || this.hrDetailDto.engLnmNmLast == null))
    ) {
      tempUpdate = false;
      notInput = '영문성명 LastName';
    }
    if (
      (isCompltedSave && (this.hrDetailDto.ntnlNm == '' || this.hrDetailDto.ntnlNm == null)) ||
      (this.tabIndex === 0 && (this.hrDetailDto.ntnlNm == '' || this.hrDetailDto.ntnlNm == null))
    ) {
      tempUpdate = false;
      notInput = '국적';
    }
    if (
      (isCompltedSave && (this.hrDetailDto.fgNm == '' || this.hrDetailDto.fgNm == null)) ||
      (this.tabIndex === 0 && (this.hrDetailDto.fgNm == '' || this.hrDetailDto.fgNm == null))
    ) {
      tempUpdate = false;
      notInput = '지원구분';
    }
    if (
      (isCompltedSave && (this.hrDetailDto.addrNm == '' || this.hrDetailDto.addrNm == null)) ||
      (this.tabIndex === 0 && (this.hrDetailDto.addrNm == '' || this.hrDetailDto.addrNm == null))
    ) {
      tempUpdate = false;
      notInput = '주소';
    }
    if (
      (isCompltedSave && (this.hrDetailDto.dutyDcDepart1 == '' || this.hrDetailDto.dutyDcDepart1 == null)) ||
      (this.tabIndex === 0 && (this.hrDetailDto.dutyDcDepart1 == '' || this.hrDetailDto.dutyDcDepart1 == null))
    ) {
      tempUpdate = false;
      notInput = '1지망 희망부서';
    }
    if (
      (isCompltedSave && (this.hrDetailDto.dutyDc1 == '' || this.hrDetailDto.dutyDc1 == null)) ||
      (this.tabIndex === 0 && (this.hrDetailDto.dutyDc1 == '' || this.hrDetailDto.dutyDc1 == null))
    ) {
      tempUpdate = false;
      notInput = '1지망 희망직무';
    }
    if (
      (isCompltedSave && (this.hrDetailDto.dutyDcDepart2 == '' || this.hrDetailDto.dutyDcDepart2 == null)) ||
      (this.tabIndex === 0 && (this.hrDetailDto.dutyDcDepart2 == '' || this.hrDetailDto.dutyDcDepart2 == null))
    ) {
      tempUpdate = false;
      notInput = '2지망 희망부서';
    }
    if (
      (isCompltedSave && (this.hrDetailDto.dutyDc2 == '' || this.hrDetailDto.dutyDc2 == null)) ||
      (this.tabIndex === 0 && (this.hrDetailDto.dutyDc2 == '' || this.hrDetailDto.dutyDc2 == null))
    ) {
      tempUpdate = false;
      notInput = '2지망 희망직무';
    }
    if (
      (isCompltedSave && (this.hrDetailDto.requestPath == '' || this.hrDetailDto.requestPath == null)) ||
      (this.tabIndex === 0 && (this.hrDetailDto.requestPath == '' || this.hrDetailDto.requestPath == null))
    ) {
      tempUpdate = false;
      notInput = '지원경로';
    }
    if (
      (isCompltedSave &&
        this.hrDetailDto.requestPath != '' &&
        this.hrDetailDto.requestPath != '신문기사(온라인,오프라인)' &&
        this.hrDetailDto.requestPath != '직접문의' &&
        (this.hrDetailDto.requestPathSub == '' || this.hrDetailDto.requestPathSub == null)) ||
      (this.tabIndex === 0 &&
        this.hrDetailDto.requestPath != '' &&
        this.hrDetailDto.requestPath != '신문기사(온라인,오프라인)' &&
        this.hrDetailDto.requestPath != '직접문의' &&
        (this.hrDetailDto.requestPathSub == '' || this.hrDetailDto.requestPathSub == null))
    ) {
      tempUpdate = false;
      notInput = '지원경로상세';
    }
    if (
      (isCompltedSave && (this.hrDetailDto.workingStatus == '' || this.hrDetailDto.workingStatus == null)) ||
      (this.tabIndex === 0 && (this.hrDetailDto.workingStatus == '' || this.hrDetailDto.workingStatus == null))
    ) {
      tempUpdate = false;
      notInput = '재직상태';
    }
    if (
      (isCompltedSave && (this.hrDetailDto.workingEnableDay == '' || this.hrDetailDto.workingEnableDay == null)) ||
      (this.tabIndex === 0 && (this.hrDetailDto.workingEnableDay == '' || this.hrDetailDto.workingEnableDay == null))
    ) {
      tempUpdate = false;
      notInput = '입사가능 시기';
    }
    if (
      (isCompltedSave && (this.hrDetailDto.workingWantPay == '' || this.hrDetailDto.workingWantPay == null)) ||
      (this.tabIndex === 0 && (this.hrDetailDto.workingWantPay == '' || this.hrDetailDto.workingWantPay == null))
    ) {
      tempUpdate = false;
      notInput = '희망연봉';
    }
    if (
      (isCompltedSave && (this.hrDetailDto.workingWantPay == '' || this.hrDetailDto.workingWantPay == null)) ||
      (this.tabIndex === 0 && (this.hrDetailDto.workingWantPay == '' || this.hrDetailDto.workingWantPay == null))
    ) {
      tempUpdate = false;
      notInput = '희망연봉';
    }

    if (
      (isCompltedSave && (this.hrDetailDto.prGrowth == '' || this.hrDetailDto.prGrowth == null)) ||
      (this.tabIndex === 5 && (this.hrDetailDto.prGrowth == '' || this.hrDetailDto.prGrowth == null))
    ) {
      tempUpdate = false;
      notInput = '성장과정';
    }
    if (
      (isCompltedSave && (this.hrDetailDto.prStrenghtWeak == '' || this.hrDetailDto.prStrenghtWeak == null)) ||
      (this.tabIndex === 5 && (this.hrDetailDto.prStrenghtWeak == '' || this.hrDetailDto.prStrenghtWeak == null))
    ) {
      tempUpdate = false;
      notInput = '장단점';
    }
    if (
      (isCompltedSave && (this.hrDetailDto.prMotivation == '' || this.hrDetailDto.prMotivation == null)) ||
      (this.tabIndex === 5 && (this.hrDetailDto.prMotivation == '' || this.hrDetailDto.prMotivation == null))
    ) {
      tempUpdate = false;
      notInput = '지원동기';
    }
    if (
      (isCompltedSave && (this.hrDetailDto.prDream == '' || this.hrDetailDto.prDream == null)) ||
      (this.tabIndex === 5 && (this.hrDetailDto.prDream == '' || this.hrDetailDto.prDream == null))
    ) {
      tempUpdate = false;
      notInput = '포부';
    }

    if (!tempUpdate) {
      this.$bvModal.msgBoxOk(`${notInput}를(을) 입력해주세요`, {
        title: '필수 입력 확인',
        okVariant: 'danger',
        centered: true,
      });
      this.hideLoadingModal();
      return;
    }
    if ((isCompltedSave && this.idPhoto == null) || (this.tabIndex === 0 && this.idPhoto == null)) {
      this.$bvModal.msgBoxOk(`증명사진을 입력해주세요`, {
        title: '필수 입력 확인',
        okVariant: 'danger',
        centered: true,
      });
      this.hideLoadingModal();
      return;
    }

    let submitDto = {...this.hrDetailDto};
    const listHrRequestExtraInfo = this.hrDetailDto.extraInfos!;
    //기타 정보 없이 등록 - 따로 처리
    delete submitDto.extraInfos;
    delete submitDto.attachFiles;
    //증명사진 추가
    submitDto.idPhoto = this.idPhoto as unknown as string;
    submitDto.accessToken = this.accessToken;

    console.log(submitDto);
    //const response = await hrBoardService.updateHrRequestDetail(submitDto);

    const apiPath = `/hrrequest/updateHrRequest`;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    //토큰 저장
    await AXIOS.post(apiPath, submitDto)
      .then(async response => {
        console.log(response);
        //기타정보 설정
        const postDataExtra = {
          listHrRequestExtraInfo: listHrRequestExtraInfo,
          accessToken: this.accessToken,
        };
        await self.updateHrRequestExtraInfo(postDataExtra);

        const uploadResTotal = await self.updateAttachFiles();

        self.clearAllAttachFiles();
        if (!isCompltedSave) {
          await self.fetchHrRequestData(false);
          let fileUploadError = '';
          if (uploadResTotal.filter(x => !x.isSuccessUpload).length > 0) {
            for (const item of uploadResTotal.filter(x => !x.isSuccessUpload)) {
              fileUploadError += `<br>${item.realFileName} : <font color="red">${item.failReason}</font>`;
            }

            this.$bvModal.msgBoxOk(
              self.htmlToVNode(
                `<font color="red">파일중 업로드하지 못한 파일을 확인해주세요</font><br>` + fileUploadError,
              ),
              {
                title: '저장성공',
                okVariant: 'success',
                centered: true,
              },
            );
          } else {
            await this.$bvModal.msgBoxOk(`저장성공했습니다`, {
              title: '저장성공',
              okVariant: 'success',
              centered: true,
            });
            if (isCompltedSave) {
              this.$router.push('/p/HrAbout');
            }
          }
        }
      })
      .catch(async function (error) {
        console.log(error);
        if (error.response) {
          // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          if (error.response.status === 404) {
            await self.$bvModal
              .msgBoxOk(self.htmlToVNode('지원서를 찾을 수 없습니다'), {
                title: '채용공고 업데이트 실패',
                okVariant: 'danger',
                okTitle: '확인',
                centered: true,
              })
              .then(value => {
                if (value === true) {
                  //self.$router.go(-1);
                  return;
                }
              });
            return;
          } else if (error.response.status === 403) {
            await self.$bvModal
              .msgBoxOk(self.htmlToVNode('지원가능기간이 아닙니다'), {
                title: '채용공고 업데이트 실패',
                okVariant: 'danger',
                okTitle: '확인',
                centered: true,
              })
              .then(value => {
                if (value === true) {
                  //self.$router.go(-1);
                  return;
                }
              });
            return;
          }
          await self.$bvModal.msgBoxOk('지원서 업데이트 도중 오류발생', {
            title: '지원서 업데이트 도중 오류발생',
            centered: true,
          });
        } else if (error.request) {
          await self.$bvModal.msgBoxOk('지원서 업데이트 도중 오류발생', {
            title: '지원서 업데이트 도중 오류발생',
            centered: true,
          });
          console.log(error.request);
        } else {
          // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
          await self.$bvModal.msgBoxOk('지원서 업데이트 도중 오류발생', {
            title: '지원서 업데이트 도중 오류발생',
            centered: true,
          });
          //console.log('Error', error.message);
        }
        //console.log(error.config);
      })
      .finally(() => {
        self.hideLoadingModal();
      });
  }

  async updateAttachFiles(): Promise<HrRequestAttachFileUploadResultDto[]> {
    //파일첨부 처리
    //삭제

    //졸업증명서
    if (this.fileUploadJolup.uploadedToDelete.length > 0) {
      const deleteIds = this.fileUploadJolup.uploadedToDelete.map((x: any) => x.id);
      await AXIOS.post('/hr/files/delete', {ids: deleteIds});
    }
    //성적증명서
    if (this.fileUploadSeongjek.uploadedToDelete.length > 0) {
      const deleteIds = this.fileUploadSeongjek.uploadedToDelete.map((x: any) => x.id);
      await AXIOS.post('/hr/files/delete', {ids: deleteIds});
    }
    //자격증증명서
    if (this.fileUploadJa.uploadedToDelete.length > 0) {
      const deleteIds = this.fileUploadJa.uploadedToDelete.map((x: any) => x.id);
      await AXIOS.post('/hr/files/delete', {ids: deleteIds});
    }
    //경력증명서
    if (this.fileUploadCareer.uploadedToDelete.length > 0) {
      const deleteIds = this.fileUploadCareer.uploadedToDelete.map((x: any) => x.id);
      await AXIOS.post('/hr/files/delete', {ids: deleteIds});
    }
    //원천징수증명서
    if (this.fileUploadWoncheon.uploadedToDelete.length > 0) {
      const deleteIds = this.fileUploadWoncheon.uploadedToDelete.map((x: any) => x.id);
      await AXIOS.post('/hr/files/delete', {ids: deleteIds});
    }
    let uploadResTotal: HrRequestAttachFileUploadResultDto[] = [];
    //업로드 대기파일 업로드
    if (this.fileUploadJolup.uploading && this.fileUploadJolup.uploading.length > 0) {
      const uploadRes = await this.uploadFiles(
        this.hrDetailDto.id!,
        '졸업증명서',
        false,
        this.fileUploadJolup.uploading,
        (evt: any) => {
          console.log(`onUploadProgress : ${evt}`);
        },
      );
      uploadResTotal.push(...uploadRes.data);
    }

    if (this.fileUploadSeongjek.uploading && this.fileUploadSeongjek.uploading.length > 0) {
      const uploadRes = await this.uploadFiles(
        this.hrDetailDto.id!,
        '성적증명서',
        false,
        this.fileUploadSeongjek.uploading,
        (evt: any) => {
          console.log(`onUploadProgress : ${evt}`);
        },
      );
      uploadResTotal.push(...uploadRes.data);
    }
    if (this.fileUploadJa.uploading && this.fileUploadJa.uploading.length > 0) {
      const uploadRes = await this.uploadFiles(
        this.hrDetailDto.id!,
        '자격증증명서',
        false,
        this.fileUploadJa.uploading,
        (evt: any) => {
          console.log(`onUploadProgress : ${evt}`);
        },
      );
      uploadResTotal.push(...uploadRes.data);
    }
    if (this.fileUploadCareer.uploading && this.fileUploadCareer.uploading.length > 0) {
      const uploadRes = await this.uploadFiles(
        this.hrDetailDto.id!,
        '경력증명서',
        false,
        this.fileUploadCareer.uploading,
        (evt: any) => {
          console.log(`onUploadProgress : ${evt}`);
        },
      );
      uploadResTotal.push(...uploadRes.data);
    }
    if (this.fileUploadWoncheon.uploading && this.fileUploadWoncheon.uploading.length > 0) {
      const uploadRes = await this.uploadFiles(
        this.hrDetailDto.id!,
        '원천징수증명서',
        false,
        this.fileUploadWoncheon.uploading,
        (evt: any) => {
          console.log(`onUploadProgress : ${evt}`);
        },
      );
      uploadResTotal.push(...uploadRes.data);
    }

    return uploadResTotal;
    //첨부파일 전체 새로고침
    //this.clearAllAttachFiles();
    //this.refreshAttachFiles();
  }

  async updateHrRequestExtraInfo(updateHrRequestExtraInfoDto: any) {
    const apiPath = `/hrrequest/HrRequestExtraInfo`;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    //토큰 저장
    await AXIOS.post(apiPath, updateHrRequestExtraInfoDto)
      .then(async response => {
        console.log(response);
        self.hrDetailDto.extraInfos = response.data;
        return;
      })
      .catch(function (error) {
        if (error.response) {
          self.$bvModal.msgBoxOk('기타정보 입력도중 저장중 오류발생', {
            title: '기타정보 입력도중 저장중 오류발생',
            centered: true,
          });
          console.log(error.response);
        } else if (error.request) {
          self.$bvModal.msgBoxOk('기타정보 입력도중 저장중 오류발생', {
            title: '기타정보 입력도중 저장중 오류발생',
            centered: true,
          });
          console.log(error.request);
        } else {
          // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
          self.$bvModal.msgBoxOk('기타정보 입력도중 저장중 오류발생', {
            title: '기타정보 입력도중 저장중 오류발생',
            centered: true,
          });
          //console.log('Error', error.message);
        }
        //console.log(error.config);
      });
  }

  async uploadFiles(
    hrRequestId: number,
    attachFileType: AttachFileType,
    isImage: boolean,
    files: any,
    onUploadProgress: any,
  ) {
    const formData = new FormData();
    formData.append('hrRequestId', String(hrRequestId));
    formData.append('isImage', String(isImage));
    formData.append('attachFileType', attachFileType);
    files.forEach((f: any) => {
      formData.append('files', f);
    });

    return AXIOS.post<HrRequestAttachFileUploadResultDto[]>('/hr/files/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
  }

  checkPrivacy() {
    if (!this.agree1 || !this.agree2 || !this.agree3) {
      this.$bvModal.msgBoxOk('개인정보 수집에 동의 체크를 해주세요', {
        title: '동의필요',
        okVariant: 'danger',
        centered: true,
      });
      return;
    }
    this.pageState = 3;
  }

  goToPrivacyPage() {
    this.$router.push('/p/Pinxpolicy/0');
  }

  private accessToken = '';

  async fetchHrRequestData(showViewPage: boolean) {
    this.showLoadingModal();
    const postData = {
      accessToken: this.accessToken,
      tokenType: 'bearer',
    };

    const apiPath = `/hrrequest/getHrRequest`;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    //토큰 저장
    await AXIOS.post(apiPath, postData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        const hrRequest: HrRequestDetail = response.data;
        if (!hrRequest.extraInfos) hrRequest.extraInfos = [];
        if (!hrRequest.attachFiles) hrRequest.attachFiles = [];
        if (!hrRequest.prGrowth) hrRequest.prGrowth = '';
        if (!hrRequest.prStrenghtWeak) hrRequest.prStrenghtWeak = '';
        if (!hrRequest.prMotivation) hrRequest.prMotivation = '';
        if (!hrRequest.prDream) hrRequest.prDream = '';
        this.hrDetailDto = hrRequest;
        this.setLocalVariable();
        if (hrRequest.idPhoto) this.idPhoto = hrRequest.idPhoto;
        //첨부파일 새로고침
        self.refreshAttachFiles(hrRequest.attachFiles);

        if (this.hrDetailDto.isTempPw) {
          //임시비번일경우
          console.log('임시비번일경우');
          this.pageState = -11;
          //this.pageState = 1;
        } else {
          if (showViewPage) this.pageState = 11;
        }
      })
      .catch(async function (error) {
        if (error.response) {
          self.$bvModal.msgBoxOk('지원자 조회중 오류발생', {
            title: '지원자 조회중 오류발생',
            centered: true,
          });
        } else if (error.request) {
          self.$bvModal.msgBoxOk('지원자 조회중 오류발생', {
            title: '지원자 조회중 오류발생',
            centered: true,
          });
          console.log(error.request);
        } else {
          // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
          self.$bvModal.msgBoxOk('지원자 조회중 오류발생', {
            title: '지원자 조회중 오류발생',
            centered: true,
          });
          //console.log('Error', error.message);
        }
        //console.log(error.config);
      })
      .finally(() => {
        self.hideLoadingModal();
      });
  }

  async checkHrUser() {
    if (this.hrUserDto.kor_nm == '') {
      this.$bvModal.msgBoxOk('성명을 입력해주세요', {
        title: '정보입력필요',
        okVariant: 'danger',
        centered: true,
      });
      return;
    }
    if (this.hrUserDto.email_nm == '') {
      this.$bvModal.msgBoxOk('이메일을 입력해주세요', {
        title: '정보입력필요',
        okVariant: 'danger',
        centered: true,
      });
      return;
    }
    if (this.hrUserDto.email_nm == '') {
      this.$bvModal.msgBoxOk('이메일을 입력해주세요', {
        title: '정보입력필요',
        okVariant: 'danger',
        centered: true,
      });
      return;
    }
    if (this.hrUserDto.biryymm_dt == '') {
      this.$bvModal.msgBoxOk('생년월일을 입력해주세요', {
        title: '정보입력필요',
        okVariant: 'danger',
        centered: true,
      });
      return;
    }
    if (this.hrUserDto.hp_no == '') {
      this.$bvModal.msgBoxOk('연락처(핸드폰)을 입력해주세요', {
        title: '정보입력필요',
        okVariant: 'danger',
        centered: true,
      });
      return;
    }
    if (this.hrUserDto.scret_no == '') {
      this.$bvModal.msgBoxOk('비밀번호를 입력해주세요', {
        title: '정보입력필요',
        okVariant: 'danger',
        centered: true,
      });
      return;
    }
    this.showLoadingModal();
    const postData = {
      koNm: this.hrUserDto.kor_nm,
      emailNm: this.hrUserDto.email_nm,
      biryymmDt: this.hrUserDto.biryymm_dt,
      hpNo: this.hrUserDto.hp_no,
      hrBoardInfoId: this.hrRequestId,
      scretNo: this.hrUserDto.scret_no,
    };

    const apiPath = `/hrrequest/checkHrUser`;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    //토큰 저장
    await AXIOS.post(apiPath, postData)
      .then(async response => {
        this.accessToken = response.data.accessToken;
        await this.fetchHrRequestData(true);
      })
      .catch(function (error) {
        if (error.response) {
          // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          if (error.response.status === 404) {
            if (!self.isEnableRequest) {
              self.$bvModal.msgBoxOk('본 채용공고 지원 기간이 아닙니다.', {
                title: '알림',
                centered: true,
              });
              return;
            } else {
              self.$bvModal
                .msgBoxConfirm(
                  self.htmlToVNode('입사 지원 이력이 없습니다.<br>본 채용공고로 입사지원을 하시겠습니까?'),
                  {
                    title: '채용공고 지원 확인',
                    okVariant: 'success',
                    okTitle: '지원하기',
                    cancelTitle: '취소',
                    centered: true,
                  },
                )
                .then(value => {
                  if (value === true) {
                    self.pageState = 2;
                  }
                });
              return;
            }
          } else if (error.response.status === 409) {
            //비밀번호 틀림
            self.$bvModal
              .msgBoxOk('입력된 정보를 확인해주세요', {
                title: '알림',
                centered: true,
              })
              .then(value => {
                self.pageState = -1;
              });
            return;
          }
          self.$bvModal.msgBoxOk('지원자 조회중 오류발생', {
            title: '지원자 조회중 오류발생',
            centered: true,
          });
        } else if (error.request) {
          self.$bvModal.msgBoxOk('지원자 조회중 오류발생', {
            title: '지원자 조회중 오류발생',
            centered: true,
          });
          console.log(error.request);
        } else {
          // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
          self.$bvModal.msgBoxOk('지원자 조회중 오류발생', {
            title: '지원자 조회중 오류발생',
            centered: true,
          });
          //console.log('Error', error.message);
        }
        //console.log(error.config);
      })
      .finally(() => {
        self.hideLoadingModal();
      });
  }

  setLocalVariable() {
    if (this.hrDetailDto.workingEnableDay)
      this.workingEnableDay = moment(this.hrDetailDto.workingEnableDay, CONST.DATEONLY_FORMAT).toDate();
    else this.workingEnableDay = moment().toDate();
  }

  initInitPWForm() {
    this.hrUserDtoInitPW.koNm = '';
    this.hrUserDtoInitPW.emailNm = '';
    this.hrUserDtoInitPW.hpNo = '';
    this.hrUserDtoInitPW.biryymmDt = '';
  }

  getLocalVariable() {
    this.hrDetailDto.workingEnableDay = moment(this.workingEnableDay).format(CONST.DATEONLY_FORMAT);
  }

  clearAllAttachFiles() {
    this.$refs.fileUploadJolup.clear();
    this.$refs.fileUploadSeongjek.clear();
    this.$refs.fileUploadJa.clear();
    this.$refs.fileUploadCareer.clear();
    this.$refs.fileUploadWoncheon.clear();
  }

  async refreshAttachFiles(attachFiles: HrRequestAttachFile[]) {
    //첨부파일 로딩
    this.fileUploadJolup.uploaded = attachFiles.filter(x => x.attachFileType == '졸업증명서');
    this.fileUploadSeongjek.uploaded = attachFiles.filter(x => x.attachFileType == '성적증명서');
    this.fileUploadJa.uploaded = attachFiles.filter(x => x.attachFileType == '자격증증명서');
    this.fileUploadCareer.uploaded = attachFiles.filter(x => x.attachFileType == '경력증명서');
    this.fileUploadWoncheon.uploaded = attachFiles.filter(x => x.attachFileType == '원천징수증명서');
  }

  async InitHrRequestPW() {
    if (this.retInfo == '') {
      this.$bvModal.msgBoxOk('본인인증을 해주세요', {
        title: '본인인증 필요',
        okVariant: 'success',
        centered: true,
      });
      return;
    }

    const apiPath = `/hrrequest/initHrRequestPW/${this.dtoHrBoard!.id!}/${this.hrUserDtoInitPW.emailNm}/${
      this.retInfo
    }`;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    //토큰 저장
    await AXIOS.get(apiPath)
      .then(async response => {
        self.$bvModal
          .msgBoxOk(
            self.htmlToVNode(
              '등록된 이메일로 임시 비밀번호를 전송하였습니다.<br>해당 비밀번호로 로그인 후 비밀번호를 변경하세요.',
            ),
            {
              title: '비밀번호 찾기',
              okVariant: 'success',
              okTitle: '확인',
              centered: true,
            },
          )
          .then(value => {
            self.pageState = 1;
            self.initInitPWForm();
          });
        return;
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 404) {
            self.$bvModal
              .msgBoxOk(self.htmlToVNode('지원서를 찾을수 없습니다.<br> 입력 정보를 확인해주세요'), {
                title: '지원서 검색불가',
                okVariant: 'success',
                okTitle: '확인',
                centered: true,
              })
              .then(value => {
                self.pageState = 1;
                self.initInitPWForm();
              });
            return;
          } else if (error.response.status === 400) {
            self.$bvModal
              .msgBoxOk(self.htmlToVNode('올바르지 않은 접근입니다'), {
                title: '지원서 검색불가',
                okVariant: 'success',
                okTitle: '확인',
                centered: true,
              })
              .then(value => {
                self.initInitPWForm();
                self.$router.go(-1);
              });
            return;
          }
          self.$bvModal.msgBoxOk('비밀번호 찾기도중 오류발생', {
            title: '비밀번호 찾기도중 오류발생',
            centered: true,
          });
        } else if (error.request) {
          self.$bvModal.msgBoxOk('비밀번호 찾기도중 오류발생', {
            title: '비밀번호 찾기도중 오류발생',
            centered: true,
          });
          console.log(error.request);
        } else {
          // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
          self.$bvModal.msgBoxOk('비밀번호 찾기도중 오류발생', {
            title: '비밀번호 찾기도중 오류발생',
            centered: true,
          });
          //console.log('Error', error.message);
        }
        //console.log(error.config);
      })
      .finally(() => {
        self.hideLoadingModal();
      });
  }

  async ChangeHrRequestPW() {
    if (this.newPassword == '') {
      this.$bvModal.msgBoxOk('비밀번호를 입력해주세요', {
        title: '비밀번호 입력필요',
        okVariant: 'danger',
        centered: true,
      });
      return;
    }
    if (this.newPassword != this.newPasswordRepeat) {
      this.$bvModal.msgBoxOk('비밀번호가 다릅니다', {
        title: '비밀번호 확인필요',
        okVariant: 'danger',
        centered: true,
      });
      return;
    }

    const isValid = await this.$refs.observer2.validate();
    if (!isValid) {
      await this.$bvModal.msgBoxOk('비밀번호 규칙에 맞게 입력해주세요', {
        title: '비밀번호 규칙확인',
      });
      return;
    }

    this.showLoadingModal();
    const postData = {
      accessToken: this.accessToken,
      tokenType: 'bearer',
    };

    const apiPath = `/hrrequest/chageNewPassword/${this.newPassword}`;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    //토큰 저장
    await AXIOS.post(apiPath, postData)
      .then(async response => {
        self.$bvModal
          .msgBoxOk(self.htmlToVNode('비밀번호를 변경하였습니다.<br>다시 로그인하세요.'), {
            title: '비밀번호 변경',
            okVariant: 'success',
            okTitle: '확인',
            centered: true,
          })
          .then(value => {
            self.pageState = 1;
          });
        return;
      })
      .catch(function (error) {
        if (error.response) {
          self.$bvModal.msgBoxOk('비밀번호 변경중 오류발생', {
            title: '비밀번호 변경중 오류발생',
            centered: true,
          });
        } else if (error.request) {
          self.$bvModal.msgBoxOk('비밀번호 변경중 오류발생', {
            title: '비밀번호 변경중 오류발생',
            centered: true,
          });
        } else {
          // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
          self.$bvModal.msgBoxOk('비밀번호 변경중 오류발생', {
            title: '비밀번호 변경중 오류발생',
            centered: true,
          });
          //console.log('Error', error.message);
        }
        //console.log(error.config);
      })
      .finally(() => {
        self.hideLoadingModal();
      });
  }

  async sendEmailAuthCode(): Promise<void> {
    const emailAddr = this.hrUserDtoNew.emailNm;
    if (emailAddr === '') {
      this.$bvModal.msgBoxOk('이메일 입력하세요', {
        title: '이메일입력확인',
        okVariant: 'danger',
        centered: true,
      });
      return;
    } else {
      let apiPath = '/SendEmailAuthCode/' + emailAddr;
      await AXIOS.get<any>(apiPath).then(response => {
        if (response.status == 200) {
          this.$bvModal.msgBoxOk('이메일 발송성공 이메일에서 인증코드를 확인후 입력해주세요', {
            title: '성공',
            okVariant: 'success',
            centered: true,
          });
        } else {
          this.$bvModal
            .msgBoxOk('이메일 발송실패', {
              title: '오류발생',
              okVariant: 'danger',
              centered: true,
            })
            .then(value => {
              this.chkEmailAuth = false;
            });
        }
      });
    }
  }

  async checkEmailAuthCode(): Promise<void> {
    if (this.emailAuthCode === '') {
      this.$bvModal.msgBoxOk('인증코드를 입력하세요', {
        title: '인증코드입력필요',
        okVariant: 'danger',
        centered: true,
      });
      return;
    }
    let apiPath = '/CheckEmailAuthCode/' + this.emailAuthCode;
    await AXIOS.get<any>(apiPath)
      .then(response => {
        if (response.status == 200) {
          this.$bvModal
            .msgBoxOk('이메일 인증성공', {
              title: '성공',
              okVariant: 'success',
              centered: true,
            })
            .then(value => {
              this.chkEmailAuth = true;
            });
        } else {
          this.$bvModal
            .msgBoxOk('이메일 인증실패', {
              title: '오류발생',
              okVariant: 'danger',
              centered: true,
            })
            .then(value => {
              this.chkEmailAuth = false;
            });
        }
      })
      .catch(error => {
        this.$bvModal
          .msgBoxOk('인증번호를 다시 확인해 주시기 바랍니다.', {
            title: '이메일 인증실패',
            okVariant: 'danger',
            centered: true,
          })
          .then(value => {
            this.chkEmailAuth = false;
          });
      });
  }

  openAddrPopup() {
    this.$bvModal.show('hr_addr_popup');
  }

  onCompletePostCode(result: any) {
    this.hrDetailDto.addrNm = result.address as string;
    this.$bvModal.hide('hr_addr_popup');
  }

  cancelPWFind() {
    this.clearAuthInfo();
    this.pageState = 1;
  }

  clearAuthInfo() {
    this.hrUserDtoInitPW.koNm = '';
    this.hrUserDtoInitPW.emailNm = '';
    this.hrUserDtoInitPW.biryymmDt = '';
    this.hrUserDtoInitPW.hpNo = '';
    this.hrUserDto.kor_nm = '';
    this.hrUserDto.email_nm = '';
    this.hrUserDto.biryymm_dt = '';
    this.hrUserDto.hp_no = '';
    this.identityAuth = false;
    this.agree1 = false;
    this.agree2 = false;
    this.agree3 = false;
    this.agree4 = false;
  }
}
