import axios, {AxiosInstance} from 'axios';
import {User} from '@/models';
import {HrToken} from '@/model-hr';
import store from '../store';

const AXIOS = axios.create({
  // headers Bearer 토큰은 App.vue 에서 모니터링해서 설정됨.
  baseURL: '/api',
  timeout: 600000,
});

export default AXIOS;
