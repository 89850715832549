











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Breadcrumb from '@/components/Breadcrumb.vue';
import Component, {mixins} from 'vue-class-component';
import GeneralUtil from '@/util/GeneralUtil';
import {Prop, Ref} from 'vue-property-decorator';
import {BoardArticle, BoardArticleResponse, RepoPage, User} from '@/models';
import AXIOS from '../services/axios-wrapper';
import {HrBoardArticle, HrRequestDetail} from '../model-hr';
import hrBoardService from '@/services/hr.service';

declare global {
  interface Window {
    Android: any;
  }
}

@Component({
  components: {
    GeneralUtil,
    Breadcrumb,
  },
})
export default class HrRequestView extends GeneralUtil {
  @Prop() readonly dtoHrBoard!: HrBoardArticle;
  @Prop() readonly hrDetailDto!: HrRequestDetail;

  async download(id: number) {
    const response = await hrBoardService.download(id);
    this.processFileDownloadResponse(response);
  }
  IsNotExistExtraInfos(extraType: string): boolean {
    let isExist = false;
    if (this.hrDetailDto.extraInfos) {
      return this.hrDetailDto.extraInfos.filter(x => x.extraType === extraType).length === 0;
    } else return isExist;
  }
  processFileDownloadResponse(response: any) {
    // console.log(window.Android);
    const fileNameHeader = 'content-disposition';
    let suggestedFileName = response.headers[fileNameHeader];

    // console.log(suggestedFileName);
    // console.log(response);
    // eslint-disable-next-line
    suggestedFileName = suggestedFileName.match(/filename\*=(.*)''(.*)/)[2]; // last match
    suggestedFileName = decodeURIComponent(suggestedFileName);
    if (window.Android) {
      // 안드로이드 는 JavascriptInterface 를 통해서 base64 인코딩된 문자열로 안드로이드로 넘기고
      // 안드로이드 내에서 base64 -> binary 로 파일을 쓸 수 있게 처리함. 파일 이름(확장자)도 같이 넘김.
      const reader = new FileReader();
      reader.readAsDataURL(response.data); // base64 인코딩된 문자열로 blob 읽어들이기
      reader.onloadend = function () {
        const base64data = reader.result;
        window.Android.getBase64FromBlobData(base64data, suggestedFileName);
        // console.log('processFileDownloadResponse Android finished');
      };
      return;
    }
    const blob = new Blob([response.data], {type: 'application/octet-stream'});
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = suggestedFileName;
    link.click();
    URL.revokeObjectURL(link.href);
  }

  fields_highschool: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'fromDt',
      label: '입학년월',
    },
    {
      key: 'toDt',
      label: '졸업년월',
      //thClass: 'red',
    },
    {
      key: 'mclsDc1',
      label: '학교명',
    },
    {
      key: 'mclsDc2',
      label: '소재지',
    },
    {
      key: 'mclsDc3',
      label: '계열',
    },
    {
      key: 'mclsDc4',
      label: '졸업여부',
    },
  ];
  fields_academic: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'fromDt',
      label: '입학년월',
    },
    {
      key: 'toDt',
      label: '졸업년월',
      //thClass: 'red',
    },
    {
      key: 'mclsDc1',
      label: '학교명',
    },
    {
      key: 'mclsDc2',
      label: '학과(전공)',
    },
    {
      key: 'mclsDc3',
      label: '학년',
    },
    {
      key: 'mclsDc4',
      label: '졸업여부',
    },
  ];
  fields_career: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'mclsDc1',
      label: '회사명',
    },
    {
      key: 'fromDt',
      label: '시작년월',
    },
    {
      key: 'toDt',
      label: '마침년월',
      //thClass: 'red',
    },
    {
      key: 'mclsDc2',
      label: '직위',
    },
    {
      key: 'mclsDc3',
      label: '직무',
    },
    {
      key: 'beforePay',
      label: '최종연봉',
    },
    {
      key: 'mclsDc4',
      label: '소재지',
    },
  ];

  fields_career_skill: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'mclsDc1',
      label: '회사명',
    },
    {
      key: 'mclsDc2',
      label: '직무',
    },
    {
      key: 'mclsDc3',
      label: '업무내용',
    },
  ];
  fields_certificate: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'mclsDc1',
      label: '종류',
    },
    {
      key: 'mclsDc2',
      label: '등급',
    },
    {
      key: 'fromDt',
      label: '취득일',
    },
    {
      key: 'mclsDc3',
      label: '발급기관',
    },
  ];
  fields_language: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'mclsDc1',
      label: '종류',
    },
    {
      key: 'mclsDc2',
      label: '점수',
    },
    {
      key: 'fromDt',
      label: '취득일',
    },
    {
      key: 'mclsDc3',
      label: '시험기관',
    },
  ];
  fields_prise: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'mclsDc1',
      label: '명칭 및 단체명',
    },
    {
      key: 'mclsDc2',
      label: '수상내용',
    },
    {
      key: 'fromDt',
      label: '수상일자',
    },
  ];
  fields_social: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'mclsDc1',
      label: '소속명',
    },
    {
      key: 'mclsDc2',
      label: '활동내용',
    },
    {
      key: 'period',
      label: '활동기간',
    },
  ];

  fields_attac_files: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'realFileName',
      label: '파일명',
    },
    {
      key: 'attachFileType',
      label: '첨부파일유형',
    },
    {
      key: 'fileSize',
      label: '파일크기',
    },
  ];
  fields_army: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'mclsDc1',
      label: '구분',
    },
    {
      key: 'period',
      label: '복무기간',
    },
    {
      key: 'mclsDc2',
      label: '면제사유',
    },
    {
      key: 'mclsDc3',
      label: '역종',
    },
    {
      key: 'mclsDc4',
      label: '계급',
    },
    {
      key: 'mclsDc5',
      label: '군별/병과',
    },
  ];
  fields_bohun: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'mclsDc1',
      label: '보훈 번호',
    },
    {
      key: 'mclsDc2',
      label: '보훈 대상자',
    },
    {
      key: 'mclsDc3',
      label: '관계',
    },
  ];
  fields_jangae: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'mclsDc1',
      label: '장애종류',
    },
    {
      key: 'mclsDc2',
      label: '장애급수',
    },
  ];
}
